<template>
  <div class="error-table">
    <b2x-table-base>
      <thead>
        <tr>
          <th v-for="(header, i) in headers" :key="i">
            {{ header.title }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, i) in tableRows" :key="i" class="user-row">
          <td v-for="(value, j) in { ...data }" :key="j">
            {{ value }}
          </td>
          <td>
            <telia-button variant="text" class="info-button-wrapper" @click="handleClick(i)">
              {{ t("DETAILS") }}
            </telia-button>
          </td>
        </tr>
      </tbody>
    </b2x-table-base>
  </div>
</template>

<script>
const COLUMNS_LIMIT = 5;
import { translateMixin } from "../locale";
export default {
  name: "ErrorTable",
  mixins: [translateMixin],
  props: {
    errorSheet: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headers() {
      return this.tableColumnHeaders();
    },
    tableRows() {
      return this.tableRowData();
    },
  },
  methods: {
    tableColumnHeaders({ errorRows } = this.errorSheet) {
      return errorRows?.[0]?.cells
        .map((cell, index) => {
          if (index < COLUMNS_LIMIT) {
            return {
              title: cell.columnName,
              type: "text",
            };
          }
        })
        .filter((cell) => cell);
    },
    tableRowData({ errorRows } = this.errorSheet) {
      return errorRows?.map((row) => {
        return row.cells.filter((cell, index) => index < COLUMNS_LIMIT).map((cell) => cell.value);
      });
    },
    handleClick(index) {
      this.$emit("onClick", { rowNumber: index, sheetName: this.errorSheet.sheetName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.info-button-wrapper {
  float: right;
  padding-right: $telia-spacing-4;
}
</style>
