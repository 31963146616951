<template>
  <section>
    <telia-text-spacing>
      <telia-heading variant="title-200" tag="h1">
        {{ t("ORDER_CONFIRMATION_TITLE") }}
      </telia-heading>
      <telia-p>
        {{ t("ORDER_CONFIRMATION_TEXT") }}
        <telia-link href="/foretag/mybusiness/support/arenden">
          {{ t("ORDER_CONFIRMATION_LINK_TEXT") }}
        </telia-link>
      </telia-p>
      <telia-p>
        {{ t("ORDER_ID") }}
        <strong data-testid="order-id">
          {{ snowCaseId }}
        </strong>
      </telia-p>
    </telia-text-spacing>
  </section>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";

export default {
  name: "order-confirmation",
  mixins: [translateMixin],
  props: {
    snowCaseId: {
      type: String,
      required: true,
    },
  },
  created() {
    translateSetup();
  },
};
</script>

<style></style>
