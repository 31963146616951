import * as analytics from "@telia/b2b-web-analytics-wrapper";

export function trackStartOfOrderFlow() {
  analytics.trackEvent(
    analytics.category.BESTALL,
    analytics.action.INITIATED,
    analytics.label.TOUCHPOINT_NEW,
    0,
    [
      {
        type: analytics.customDimensions.IMPERSONATION,
        value: "End Customer",
      },
    ]
  );
}

export function trackDownload() {
  analytics.trackEvent(
    analytics.category.BESTALL,
    analytics.action.DOWNLOAD_EXCEL,
    analytics.label.TOUCHPOINT_NEW
  );
}

export function trackUpload() {
  analytics.trackEvent(
    analytics.category.BESTALL,
    analytics.action.UPLOAD_EXCEL,
    analytics.label.TOUCHPOINT_NEW
  );
}

export function trackConfirmOrder() {
  analytics.trackEvent(
    analytics.category.BESTALL,
    analytics.action.REGISTER_EXCEL,
    analytics.label.TOUCHPOINT_NEW
  );
}
