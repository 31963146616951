<template>
  <div>
    <b2x-drawer
      :heading="t('HEADER')"
      drawer-id="detailed-error-information"
      position="right"
      :is-open="showDetailedErrorInfo"
      @drawerClose="closeDrawer()"
      v-if="scopeId"
    >
      <error-details
        :details="selectedErrorRow"
        :statusHeader="t('STATUS_HEADER')"
        :columnHeader="t('COLUMN_HEADER')"
        :valueHeader="t('VALUE_HEADER')"
        :statusTranslation="{
          OK: t('OK'),
          INVALID: t('INVALID'),
          MISSING: t('MISSING'),
        }"
      >
      </error-details>
    </b2x-drawer>
    <b2b-layout
      v-if="setupCompleted"
      :page-title="t('HEADER')"
      :heading="t('HEADER')"
      :back-link-label="t('BACK_TO_PRODUCT_AND_SERVICES')"
      :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
      :show-organisation-selector="true"
      @organisationSelected="handleOrganisationChanged($event.detail)"
    >
      <telia-grid v-if="states.file.status === Status.Rejected">
        <page-alert
          class="page-alert"
          t-id="file-alert"
          :heading="states.file.message.heading"
          :body="states.file.message.content"
        />
      </telia-grid>
      <div v-else-if="!submitted && states.upload.status !== Status.Pending">
        <telia-grid class="tpp-grid">
          <telia-row class="row-bottom-margin">
            <telia-col width="12">
              <div>
                <telia-heading tag="h2" variant="title-100">{{
                  t("STEP_ONE_HEADER")
                }}</telia-heading>
                <telia-p class="sub-header">{{ t("STEP_ONE_SUB_HEADER") }}</telia-p>
                <div class="button-container">
                  <telia-button
                    class="download-button"
                    variant="secondary"
                    @click="handleDownloadClick()"
                    t-id="download-button"
                  >
                    <telia-icon name="download" slot="left" size="sm" />
                    {{ t("DOWNLOAD_EXCEL") }}
                  </telia-button>
                  <telia-button variant="primary" t-id="upload-button" class="upload-button">
                    <telia-icon name="upload" slot="left" size="sm" />
                    {{ t("UPLOAD_EXCEL") }}
                    <input
                      ref="inputFile"
                      type="file"
                      accept="application/vnd.ms-excel.sheet.macroEnabled.12"
                      class="input-file"
                      @change="onFileChange($event)"
                    />
                  </telia-button>
                  <div class="file-container" v-if="fileName && !validates">
                    <telia-p class="file-text">{{ fileName }}</telia-p>
                    <telia-icon class="remove-file" name="close" size="md" @click="handleDelete" />
                  </div>
                  <div class="file-container" v-if="validates">
                    <telia-p class="file-text">{{ t("VALIDATE_FILE") }}</telia-p>
                  </div>
                </div>
                <div v-if="!customerInformation.mail && setupCompleted" class="missing-email">
                  <telia-text-input
                    :label="t('EMAIL')"
                    :value="newEmail"
                    @input="setNewEmail($event.target.value)"
                    required
                  />
                  <telia-field-assistive-text
                    v-if="!invalidEmail"
                    v-html="t('EMAIL_IS_MANDATORY')"
                  />
                  <telia-field-error v-else v-html="t('EMAIL_IS_MANDATORY')" />
                </div>
                <div
                  v-if="!customerInformation.phoneNumber && setupCompleted"
                  class="missing-mobile-number"
                >
                  <telia-text-input
                    :label="t('MOBILE_NUMBER')"
                    :value="newMobileNumber"
                    @input="setNewPhoneNumber($event.target.value)"
                    required
                  />
                  <telia-field-assistive-text
                    v-if="!invalidPhoneNumber"
                    v-html="t('MOBILE_NR_IS_MANDATORY')"
                  />
                  <telia-field-error v-else v-html="t('MOBILE_NR_IS_MANDATORY')" />
                </div>
                <div>
                  <div class="submit-button-container">
                    <telia-button
                      variant="primary"
                      :disabled="disableSubmit"
                      @click="handleSubmitClick()"
                      t-id="submit-button"
                    >
                      {{ t("SUBMIT") }}
                    </telia-button>
                  </div>
                  <page-alert
                    v-if="states.upload.status === Status.Rejected"
                    class="page-alert"
                    t-id="upload-alert"
                    :heading="states.upload.message.heading"
                    :body="states.upload.message.content"
                  />
                  <page-alert
                    v-if="isTeliaAdmin"
                    class="page-alert"
                    t-id="telia-admin-alert"
                    :heading="t('TELIA_ADMIN_INFO_HEADING')"
                    :body="t('TELIA_ADMIN_INFO_DETAILS')"
                  />
                </div>

                <div v-for="errorSheet in errorSheets" :key="errorSheet.sheetName">
                  <telia-heading tag="h2" variant="title-100" class="table-header">
                    {{ t("WORKSHEET") }}: {{ errorSheet.sheetName }}
                  </telia-heading>
                  <div class="error-table">
                    <error-table
                      :errorSheet="errorSheet"
                      @onClick="rowClick"
                      :key="errorSheet.sheetName"
                    />
                  </div>
                </div>
              </div>
            </telia-col>
          </telia-row>
        </telia-grid>
      </div>
      <telia-grid v-else>
        <div v-if="snowCaseId" class="order-confirmation-wrapper">
          <order-confirmation :snowCaseId="snowCaseId"></order-confirmation>
        </div>
        <div v-else class="skeleton__confirmation-wrapper">
          <telia-skeleton class="skeleton__confirmation" />
          <telia-skeleton class="skeleton__confirmation" />
          <telia-skeleton class="skeleton__confirmation" />
        </div>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script>
import { fetchFile, uploadFile, validateFile } from "./services/file-handling-service";
import { getLoggedInUserInfo, getOrganizations, isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { translateSetup, translateMixin } from "./locale";
import OrderConfirmation from "./components/order-confirmation";
import PageAlert from "./components/page-alert";
import ErrorDetails from "./components/error-details";
import ErrorTable from "./components/error-table";
import {
  trackStartOfOrderFlow,
  trackDownload,
  trackUpload,
  trackConfirmOrder,
} from "./services/ga-tracking.js";

export const Status = Object.freeze({
  Idle: 0,
  Pending: 1,
  Resolved: 2,
  Rejected: 3,
});

export default {
  name: "App",
  mixins: [translateMixin],
  components: {
    OrderConfirmation,
    PageAlert,
    ErrorDetails,
    ErrorTable,
  },
  data() {
    return {
      Status,
      states: {
        file: {
          status: Status.Idle,
          message: {
            heading: "",
            content: "",
          },
          type: "",
        },
        upload: {
          status: Status.Idle,
          message: {
            heading: "",
            content: "",
          },
          type: "",
        },
      },
      isTeliaAdmin: false,
      scopeId: null,
      fileName: null,
      fileBase64: null,
      customerInformation: {
        firstName: "",
        lastName: "",
        mail: "",
        phoneNumber: "",
      },
      selectedOrganisation: {
        name: "",
        number: "",
        tscid: "",
      },
      organisations: null,
      validates: false,
      submitted: false,
      snowCaseId: "",
      errorSheets: [],
      selectedErrorRow: [],
      showDetailedErrorInfo: false,
      newEmail: "",
      newMobileNumber: "",
      invalidEmail: false,
      invalidPhoneNumber: false,
      setupCompleted: false,
    };
  },
  created() {
    translateSetup();
  },
  async mounted() {
    await this.setup();
  },
  methods: {
    async setup() {
      try {
        this.scopeId = await getScopeIdOrThrow();
      } catch {
        this.handleUserInformationFail();
        return;
      }

      try {
        this.isTeliaAdmin = await isTeliaAdmin();
        if (!this.isTeliaAdmin) {
          this.customerInformation = await getLoggedInUserInfo();
        }
      } catch {
        this.handleUserInformationFail();
        return;
      }
      try {
        this.organisations = await getOrganizations();
      } catch {
        this.handleOrganisationFail();
        return;
      }
      trackStartOfOrderFlow();
      this.setupCompleted = true;
    },
    handleUserInformationFail() {
      this.setState("file", Status.Rejected, {
        heading: this.t("COULD_NOT_FETCH_USER_INFORMATION"),
        content: this.t("COULD_NOT_FETCH_USER_INFORMATION_CONTENT"),
      });
    },
    handleOrganisationFail() {
      this.setState("file", Status.Rejected, {
        heading: this.t("ERROR.SOMETHING_WENT_WRONG"),
        content: this.t("ERROR.ORGANISATIONS"),
      });
    },
    handleOrganisationChanged(tscid) {
      this.selectedOrganisation = this.organisations.find((org) => org.tscid === tscid);
    },
    handleDownloadClick() {
      this.fetchAndDownloadFile();
    },
    setNewEmail(input) {
      this.invalidEmail = false;
      this.newEmail = input;
    },
    setNewPhoneNumber(input) {
      this.invalidPhoneNumber = false;
      this.newMobileNumber = input;
    },
    async fetchAndDownloadFile() {
      trackDownload();
      try {
        let response = await fetchFile(this.scopeId);
        let objectURL = URL.createObjectURL(response);
        let link = document.createElement("a");
        link.href = objectURL;
        link.download = "Kunddata Touchpoint Plus.xlsm";
        link.click();
        URL.revokeObjectURL(objectURL);
      } catch (e) {
        this.setState("file", Status.Rejected, {
          heading: this.t("COULD_NOT_FETCH_EXCEL_FILE_HEADING"),
          content: this.t("COULD_NOT_FETCH_EXCEL_FILE_CONTENT"),
        });
      }
    },
    async handleSubmitClick() {
      this.setState("upload", Status.Pending);

      const uploadBody = {
        account: {
          companyName: this.selectedOrganisation.name,
          organisationNumber: this.selectedOrganisation.organizationNumber,
        },
        contact: {
          email: this.customerInformation.mail || this.newEmail,
          firstName: this.customerInformation.firstName,
          lastName: this.customerInformation.lastName,
          mobileNumber: this.customerInformation.phoneNumber || this.newMobileNumber,
        },
        file: this.fileBase64,
      };

      try {
        const response = await uploadFile(this.scopeId, uploadBody);
        this.submitted = true;
        this.snowCaseId = response.snowCaseId;
        trackConfirmOrder();
        this.setState("upload", Status.Resolved);
      } catch (e) {
        this.invalidEmail = !!e?.fieldErrors?.["contact.email"];
        if (this.invalidEmail) {
          this.setState("upload", Status.Rejected, {
            heading: this.t("COULD_NOT_UPLOAD_FILE"),
            content: this.t("EMAIL_IS_MANDATORY"),
          });
          return;
        }
        this.invalidPhoneNumber = !!e?.fieldErrors?.["contact.mobileNumber"];
        if (this.invalidPhoneNumber) {
          this.setState("upload", Status.Rejected, {
            heading: this.t("COULD_NOT_UPLOAD_FILE"),
            content: this.t("MOBILE_NR_IS_MANDATORY"),
          });
          return;
        }
        this.setState("upload", Status.Rejected, {
          heading: this.t("COULD_NOT_UPLOAD_FILE"),
          content: this.t("ERROR.GENERIC_CONTENT"),
        });
      }
    },
    async onFileChange(event) {
      try {
        this.setState("file", Status.Idle);
        this.errorSheets = null;
        this.validates = true;
        this.fileName = event.target.files[0].name;
        this.createBase64(event.target.files[0]);
        trackUpload();
      } catch {
        this.fileName = null;
        this.fileBase64 = null;
        this.validates = false;
      }
    },
    createBase64(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.fileBase64 = e.target.result.split(",")[1];
        this.validateFile();
      };
      reader.readAsDataURL(file);
    },
    async validateFile() {
      this.errorSheets = null;
      try {
        let response = await validateFile(this.scopeId, {
          file: this.fileBase64,
        });
        if (response?.errorSheetCount) {
          this.setState("file", Status.Rejected, {
            heading: `${this.t("ERROR.ROW_COUNT")} ${response.totalDataRowCount}`,
            content: `${this.t("ERROR.CELL_COUNT")} ${response.totalErrorCellCount}`,
          });
          this.errorSheets = response.errorSheets;
          this.fileName = null;
          this.fileBase64 = null;
          const inputElement = this.$refs.inputFile;
          inputElement.value = null;
        }
      } catch (e) {
        this.handleDelete();
        this.setState("file", Status.Rejected, {
          heading: this.t("COULD_NOT_ADD_FILE_HEADING"),
          content: this.t(e.errorKey),
        });
      }
      this.validates = false;
    },
    handleDelete() {
      this.fileName = null;
      this.fileBase64 = null;
      const inputElement = this.$refs.inputFile;
      inputElement.value = null;
      this.errorSheets = null;
      this.setState("file", Status.Idle);
    },
    setState(state, status, message = {}, type = "alert") {
      this.states[state].status = status;
      this.states[state].message = message;
      this.states[state].type = type;
    },

    rowClick({ rowNumber, sheetName }) {
      let errorSheet = this.errorSheets.find((sheet) => sheet.sheetName === sheetName);
      this.selectedErrorRow = errorSheet.errorRows[rowNumber].cells;
      this.showDetailedErrorInfo = true;
    },
    closeDrawer() {
      this.showDetailedErrorInfo = false;
    },
  },
  computed: {
    disableSubmit() {
      return (
        this.isTeliaAdmin || !this.fileName || !this.selectedOrganisation.name || this.validates
      );
    },
  },
};
</script>

<style lang="scss">
.drawer {
  max-width: 800px;
}
</style>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/breakpoints/variables";

$gray-background: $telia-gray-50;

.tpp-grid {
  padding-bottom: 3rem;
}
.button-container {
  margin-top: $telia-spacing-16;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  .download-button,
  .upload-button {
    margin-right: $telia-spacing-16;
    margin-bottom: $telia-spacing-16;
  }

  .file-container {
    display: flex;
    align-items: center;
    margin-bottom: $telia-spacing-16;

    .file-text {
      display: inline-flex;
    }
  }

  @media screen and (max-width: $telia-breakpoint-medium) {
    display: flex;
    justify-items: center;
    flex-direction: column;
    margin-bottom: 0;

    .download-button,
    .upload-button {
      display: block;
      margin-right: 0;
      width: 100%;
    }
  }
}

.sub-header {
  margin: $telia-spacing-16 0;
}

.row-bottom-margin {
  margin-bottom: $telia-spacing-48;
}

.missing-email {
  margin: $telia-spacing-24 0;
  max-width: 48rem;
}

.missing-mobile-number {
  margin: $telia-spacing-24 0;
  max-width: 48rem;
}

.input-file {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.submit-button-container {
  margin-top: $telia-spacing-16;
  margin-bottom: $telia-spacing-16;
}

.remove-file {
  cursor: pointer;
}

.error-table {
  background: $telia-white;
}

.table-header {
  margin-top: $telia-spacing-24;
  margin-bottom: $telia-spacing-20;
}

.skeleton {
  &__confirmation-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-20;
  }
  &__confirmation {
    width: 30rem;
    height: $telia-spacing-24;
  }
}
</style>
