<template>
  <div class="page-alert">
    <telia-notification
      heading-tag="h4"
      variant="inline"
      :status="variant"
      html-aria-live="assertive"
      html-role="alert"
    >
      <span slot="heading">
        <telia-visually-hidden>
          {{ t("SOMETHING_WENT_WRONG_ALLY_STATUS") }}
        </telia-visually-hidden>
        <span>
          {{ heading }}
        </span>
      </span>
      <span slot="content">
        <telia-visually-hidden>
          {{ t("SOMETHING_WENT_WRONG_ALLY_STATUS") }}
        </telia-visually-hidden>
        <telia-p>{{ body }}</telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script>
import { translateMixin } from "../locale";
export default {
  name: "page-alert",
  mixins: [translateMixin],
  props: {
    variant: {
      type: String,
      default: "information",
    },
    heading: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-alert {
  max-width: 52rem;
}
</style>
