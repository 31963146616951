async function catchErrors(response) {
  const errors = {
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    500: "Internal Server Error",
    502: "Bad Gateway",
    503: "Service Unavailable",
  };

  const error = errors[response.status];
  if (error) {
    let responseJson = await response.json();
    throw {
      error: error,
      errorKey: responseJson.errorKey ? responseJson.errorKey : "ERROR.GENERIC_CONTENT",
      fieldErrors: responseJson.fieldErrors ? responseJson.fieldErrors : [],
    };
  }

  if (!response.ok) {
    throw Error("Generic Error");
  }
}

async function getResponseBody(response) {
  try {
    const contentType = response.headers.get("Content-Type");
    if (contentType) {
      const isJSON = contentType.toLowerCase().startsWith("application/json");
      const isExcel = contentType
        .toLowerCase()
        .startsWith("application/vnd.ms-excel.sheet.macroenabled.12");
      if (isExcel) {
        return await response.blob();
      } else if (isJSON) {
        return await response.json();
      }
    }
  } catch (error) {
    throw Error("Generic Error");
  }
  return null;
}

async function sendRequest(request, url) {
  let response = await fetch(url, request);
  await catchErrors(response);
  return getResponseBody(response);
}

export async function fetchFile(scopeId) {
  const url = `/.api/corp-order-tpp-file-handling-service/${scopeId}/file`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/vnd.ms-excel.sheet.macroEnabled.12",
    },
  };
  return sendRequest(request, url);
}

export async function uploadFile(scopeId, body) {
  const url = `/.api/corp-order-tpp-file-handling-service/${scopeId}/file`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return sendRequest(request, url);
}

export async function validateFile(scopeId, body) {
  const url = `/.api/corp-order-tpp-file-handling-service/${scopeId}/validate`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return sendRequest(request, url);
}
