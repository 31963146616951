<template>
  <b2x-table-base>
    <thead>
      <tr>
        <th id="status-header">{{ statusHeader }}</th>
        <th id="column-header">{{ columnHeader }}</th>
        <th id="value-header">{{ valueHeader }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(cell, index) in filteredDetails" :key="index">
        <td class="cell">{{ statusTranslation[cell.status] }}</td>
        <td class="cell">{{ cell.columnName }}</td>
        <td class="cell">{{ cell.value }}</td>
      </tr>
    </tbody>
  </b2x-table-base>
</template>

<script>
export default {
  name: "error-details",
  props: {
    details: {
      type: Array,
      required: true,
    },
    statusHeader: {
      type: String,
      required: true,
    },
    columnHeader: {
      type: String,
      required: true,
    },
    valueHeader: {
      type: String,
      required: true,
    },
    statusTranslation: {
      type: Object,
      default: function () {
        return {
          OK: "Valid",
          INVALID: "Invalid",
          MISSING: "Missing",
        };
      },
    },
  },
  computed: {
    filteredDetails() {
      return this.details.filter((detail) => detail.status !== "OK");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.detail-row {
  margin-bottom: $telia-spacing-16;
}

.cell {
  padding: $telia-spacing-12;
}
</style>
